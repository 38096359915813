<template>
  <vs-card :key="rerenderKey" class="lg:mt-3">
    <div id="data-list-list-view" class="data-list-container">
      <!-- <div class="lg:hidden">
        <div class="flex flex-wrap">
          <div class="flex flex-no-wrap lg:w-1/2 w-full mb-2">
            <div class="mr-2 w-full">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                vs-multiple
                v-model="dataTableParams.limit"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
            <div class="w-full">
              <vs-select
                width="100%"
                placeholder="All"
                autocomplete
                vs-multiple
                v-model="dataTableParams.month"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in monthOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>
          <div class="flex flex-no-wrap lg:w-1/2 w-full mb-2">
            <div class="mr-2 w-full">
              <vs-select
                width="100%"
                placeholder="Year"
                autocomplete
                vs-multiple
                v-model="dataTableParams.year"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in yearOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>
          <div class="flex flex-no-wrap lg:w-1/2 w-full mb-2">
            <div v-if="!clinicInvoices" class="w-full">
              <vs-select
                width="100%"
                style="position: relative"
                placeholder="Select Clinic"
                autocomplete
                v-model="dataTableParams.clinic"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
            <div class="w-full pl-2">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
          </div>
        </div>
        <div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in invoiceData">
              <div
                class="rounded my-2 p-2 pb-0 mobile-text"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex">
                  <h5 class="mr-2 mobile-text">
                    {{
                      invoiceData[indextr].invoiceNumber
                        ? invoiceData[indextr].invoiceNumber
                        : "N/A"
                    }}
                  </h5>
                </div>
                <div class="flex my-2">
                  <h4 class="mr-2 mobile-text">
                    {{
                      invoiceData[indextr].clinicName
                        ? invoiceData[indextr].clinicName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div class="my-2">
                  <p>Month: {{ invoiceData[indextr].month }}</p>
                </div>
                <div
                  style="height: 28px"
                  class="my-2"
                  v-if="
                    ($store.state.AppActiveUser.userType === 'clinic' ||
                      $store.state.AppActiveUser.userType === 'franchise' ||
                      $store.state.AppActiveUser.userType === 'subFranchise') &&
                    (invoiceData[indextr].paidStatus === 'Failed' ||
                      invoiceData[indextr].paidStatus === 'Hold')
                  "
                >
                  <vx-tooltip text="Payment failed. Retry now." position="top">
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      @click="invoiceSelectedToPay(invoiceData[indextr])"
                      style="padding: 4px 10px"
                      >Retry payments</vs-button
                    >
                  </vx-tooltip>
                </div>
                <div
                  class="my-2"
                  style="height: 28px"
                  v-if="
                    invoiceData[indextr].paidStatus !== 'Not Proceeded' &&
                    invoiceData[indextr].paidStatus !== 'Upcoming'
                  "
                >
                  <a
                    style="float: left"
                    :href="invoiceData[indextr].pdfLink"
                    :download="getName(invoiceData[indextr])"
                    target="_blank"
                  >
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      style="padding: 4px 10px"
                      >View Invoice</vs-button
                    >
                  </a>
                </div>
                <div
                  class="my-2"
                  v-if="invoiceData[indextr].paidStatus === 'Upcoming'"
                  style="height: 28px"
                >
                  <a
                    style="float: left"
                    :href="invoiceData[indextr].pdfLink"
                    :download="getName(invoiceData[indextr])"
                    target="_blank"
                  >
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      style="padding: 4px 10px"
                      >Review Treatments</vs-button
                    >
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div> -->

      <div class="lg:hidden">
        <div class="flex flex-wrap">
          <div class="flex flex-no-wrap lg:w-1/2 w-full mb-2">
            <div class="mr-2 w-full">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                vs-multiple
                v-model="dataTableParams.limit"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
            <div class="w-full">
              <vs-select
                width="100%"
                placeholder="All"
                autocomplete
                vs-multiple
                v-model="dataTableParams.month"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in monthOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>
          <div class="flex flex-no-wrap lg:w-1/2 w-full mb-2">
            <div class="mr-2 w-full">
              <vs-select
                width="100%"
                placeholder="Year"
                autocomplete
                vs-multiple
                v-model="dataTableParams.year"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in yearOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>
          <div class="w-full">
            <vs-input
              icon="search"
              style="width: 100%"
              placeholder="Search"
              v-model="searchInputString"
            />
          </div>
        </div>
        <div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in invoiceData">
              <div
                class="rounded my-2 p-2 pb-0 mobile-text"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex justify-between">
                  <div class="flex mr-2 mobile-text invoice-date items-center">
                    {{
                      invoiceData[indextr].month +
                      " " +
                      invoiceData[indextr].year
                    }}
                  </div>
                  <div
                    class="invoice-state"
                    v-bind:class="
                      `invoice-state-` +
                      invoiceData[indextr].paidStatus.toLowerCase()
                    "
                  >
                    {{ invoiceData[indextr].paidStatus }}
                  </div>
                </div>
                <div class="flex my-2">
                  <h4 class="mr-2 mobile-text">
                    {{
                      invoiceData[indextr].clinicName
                        ? invoiceData[indextr].clinicName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div class="my-2 invoice-number">
                  {{ invoiceData[indextr].invoiceNumber }}
                </div>
                <div class="flex">
                  <div
                    style="height: 28px"
                    class="my-2"
                    v-if="
                      invoiceData[indextr].paidStatus === 'Failed' ||
                      invoiceData[indextr].paidStatus === 'Hold'
                    "
                  >
                    <vx-tooltip
                      text="Payment failed. Retry now."
                      position="top"
                    >
                      <vs-button
                        color="#3dc9b3"
                        type="border"
                        @click="invoiceSelectedToPay(invoiceData[indextr])"
                        style="padding: 4px 10px"
                        >Pay Now</vs-button
                      >
                    </vx-tooltip>
                  </div>
                  <div
                    class="my-2 px-2"
                    style="height: 28px"
                    v-if="invoiceData[indextr].pdfLink"
                  >
                    <a
                      style="float: left"
                      :href="invoiceData[indextr].pdfLink"
                      :download="getName(invoiceData[indextr])"
                      target="_blank"
                    >
                      <vs-button
                        color="#3dc9b3"
                        type="border"
                        style="padding: 4px 10px"
                        >View Invoice</vs-button
                      >
                    </a>
                  </div>
                  <div
                    class="my-2"
                    v-if="invoiceData[indextr].paidStatus === 'Upcoming'"
                    style="height: 28px"
                  >
                    <a
                      style="float: left"
                      :href="invoiceData[indextr].pdfLink"
                      :download="getName(invoiceData[indextr])"
                      target="_blank"
                    >
                      <vs-button
                        color="#3dc9b3"
                        type="border"
                        style="padding: 4px 10px"
                        >Review Treatments</vs-button
                      >
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <vs-table
        class="hidden lg:inline"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="invoiceData"
        @search="search"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap">
          <div class="mb-2">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="mb-2">
            <vs-select
              placeholder="All"
              autocomplete
              vs-multiple
              v-model="dataTableParams.month"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in monthOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="mb-2">
            <vs-select
              placeholder="Year"
              autocomplete
              vs-multiple
              v-model="dataTableParams.year"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in yearOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div v-if="!clinicInvoices" class="pb-5">
            <vs-select
              style="position: relative"
              placeholder="Select Clinic"
              autocomplete
              v-model="dataTableParams.clinic"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.clinicName"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="invoiceNumber">Invoice Number</vs-th>
          <vs-th
            v-if="
              $store.state.AppActiveUser.userType !== 'clinic' &&
              $store.state.AppActiveUser.userType !== 'subClinic'
            "
            sort-key="clinicName"
            >Clinic</vs-th
          >
          <vs-th sort-key="year">Year</vs-th>
          <vs-th sort-key="month">Month</vs-th>
          <vs-th>Cost $</vs-th>
          <!-- <vs-th sort-key="startDate">Start Date</vs-th>
          <vs-th sort-key="endData">End Date</vs-th>-->
          <!-- <vs-th sort-key="createdAt">Sent On</vs-th> -->
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].invoiceNumber">
              {{ data[indextr].invoiceNumber }}
            </vs-td>
            <vs-td
              v-if="
                $store.state.AppActiveUser.userType !== 'clinic' &&
                $store.state.AppActiveUser.userType !== 'subClinic'
              "
              :data="data[indextr].clinicName"
              >{{ data[indextr].clinicName | capitalize }}</vs-td
            >
            <vs-td :data="data[indextr].year">{{ data[indextr].year }}</vs-td>
            <vs-td :data="data[indextr].month">{{ data[indextr].month }}</vs-td>
            <!-- <vs-td :data="data[indextr].startDate">{{ data[indextr].startDate }}</vs-td>
            <vs-td :data="data[indextr].endDate">{{ data[indextr].endDate }}</vs-td>-->
            <vs-td :data="data[indextr].amount">
              {{ (data[indextr].amount / 100) | currency }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <div class="flex items-center">
                <vx-tooltip text="View Order Details">
                  <a
                    :href="data[indextr].pdfLink"
                    :download="getPdfName(data[indextr])"
                    target="blank"
                  >
                    <vs-button
                      type="flat"
                      size="small"
                      color="success"
                      class="m-1"
                      >Invoice PDF</vs-button
                    >
                  </a>
                </vx-tooltip>
                <!-- <div
                  v-if="
                    ($store.state.AppActiveUser.userType === 'clinic' ||
                      $store.state.AppActiveUser.userType === 'franchise' ||
                      $store.state.AppActiveUser.userType === 'subFranchise') &&
                    (data[indextr].paidStatus === 'Failed' ||
                      data[indextr].paidStatus === 'Hold')
                  "
                >
                  <vx-tooltip text="Payment failed. Retry now." position="top">
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      @click="invoiceSelectedToPay(data[indextr])"
                      style="padding: 4px 10px"
                      >Pay Now</vs-button
                    >
                  </vx-tooltip>
                </div> -->
                <div
                  v-if="
                    role &&
                    role.toUpperCase() === 'Org Owner'.toUpperCase() &&
                    (data[indextr].paidStatus === 'Failed' ||
                      data[indextr].paidStatus === 'Hold')
                  "
                >
                  <vx-tooltip text="Payment failed. Retry now." position="top">
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      @click="invoiceSelectedToPay(data[indextr])"
                      style="padding: 4px 10px"
                      >Pay Now</vs-button
                    >
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup :active.sync="popUpPay" title="Pay Confirmation">
        <p>Are you sure you want to pay this invoice?</p>

        <vs-row vs-justify="center" vs-mt="6">
          <div class="w-full">
            <p class="py-2">
              <strong>Invoice Number:</strong>
              {{ invoiceDataSelectedToPay.invoiceNumber }}
            </p>
            <p class="py-2">
              <strong>Invoice For:</strong>
              {{
                invoiceDataSelectedToPay.invoiceMonth +
                " " +
                invoiceDataSelectedToPay.invoiceYear
              }}
            </p>
            <p class="pt-2">
              <strong>Invoice Amount:</strong>
              {{ `${invoiceDataSelectedToPay.invoiceAmount} ${currencyType}` }}
            </p>
            <p class="pt-3">
              <strong>Selected payment methods</strong>
            </p>
            <ul class="m-0 p-0">
              <li v-for="(card, index) in cardDetails" :key="index">
                <div class="flex flex-row py-4">
                  <div class="pr-4 pt-1">
                    <vs-radio
                      v-model="selectedPayment"
                      :vs-value="
                        card.cardId ? card.cardId : card.paymentMethodId
                      "
                    />
                  </div>
                  <div v-if="card.type === 'CreditCard'">
                    <p>{{ card.brand }}</p>
                    <p>XXXX XXXX XXXX {{ card.last4 }}</p>
                    <p>
                      {{ card.exp_month }} /
                      {{ card.exp_year }}
                    </p>
                  </div>
                  <div v-if="card.type !== 'CreditCard'">
                    <p>{{ card.accountHolderName }}</p>
                    <p>{{ card.accountHolderEmail }}</p>
                    <p>
                      {{ card.bsb }} /
                      {{ card.accountNumber }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <vs-popup
              class="holamundo"
              title="Add payment method"
              :active.sync="showAddPayment"
            >
              <vs-tabs :value="paymentType === 'CreditCard' ? 0 : 1">
                <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'">
                  <stripe-elements
                    ref="stripeElement"
                    v-bind:title="saveCreditCard"
                    :pk="stripePublishableKey"
                    :amount="invoiceDataSelectedToPay.invoiceAmount"
                    locale="en"
                    @token="tokenCreated"
                    @loading="loading = $event"
                  >
                  </stripe-elements>
                  <vs-button @click="submit">Confirm And Pay</vs-button>
                </vs-tab>
                <vs-tab
                  label="Bank Account"
                  @click="paymentType = 'BankAccount'"
                >
                  <BankSetup
                    :totalPrice="invoiceDataSelectedToPay.invoiceAmount"
                    :financialDetails="financialDetails"
                    :isNewClinicBankAccount="isNewClinicBankAccount"
                    :shippingDetails="shippingDetails"
                    :paymentType="'BankAccount'"
                    :repayment="true"
                    :clinicId="invoiceDataSelectedToPay.clinicId"
                    @onClinicBankPaymentSuccess="clinicBankPaymentSuccess"
                  ></BankSetup>
                </vs-tab>
              </vs-tabs>
            </vs-popup>
            <div
              class="border py-3 mt-5"
              style="border-bottom: solid 1px #e0e0e0"
            >
              <div
                class="
                  flex flex-row
                  bg-primary
                  rounded-lg
                  text-white
                  cursor-pointer
                  p-2
                "
                style="width: 230px"
                @click="openPopup"
              >
                <plus-icon size="1.5x" class="custom-class"></plus-icon>
                <div class="pl-4 pt-1">Add Payment Method</div>
              </div>
            </div>
          </div>
        </vs-row>
        <vs-row vs-mt="6">
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button size="lg" color="primary" @click="payInvoice()"
                >Yes, pay</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button size="lg" color="danger" @click="popUpPay = false"
                >Not now</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </vs-popup>

      <div 
        class="
          m-2
          flex
          sm:justify-between
          justify-center
          items-center
          flex-wrap
        "
        style="height: 35px padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import ClinicYearMonthPopup from "../../components/invoice/ClinicYearMonthPopup";
import moment from "moment";
import BankSetup from "../../components/order-center/BankSetup";
import StripeElements from "../../components/order-center/StripeElements";

export default {
  components: { ClinicYearMonthPopup, BankSetup, StripeElements },
  props: {
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    clinicInvoices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      role: "",
      noDataText: "No invoice available",
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      rerenderKey: 0,
      isMounted: false,
      searchInputString: null,
      totalDocs: 0,
      currentPage: 1,
      cardDetails: [],
      clinics: [{ clinicId: null, clinicName: "All Clinics" }],
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        organizationId: "",
        clinic: null,
        year: "",
        month: "",
        status: "",
      },
      serverResponded: false,
      invoiceData: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      monthOptions: [
        { text: "All Months", value: "" },
        { text: "January", value: "Jan" },
        { text: "February", value: "Feb" },
        { text: "March", value: "Mar" },
        { text: "April", value: "Apr" },
        { text: "May", value: "May" },
        { text: "June", value: "Jun" },
        { text: "July", value: "Jul" },
        { text: "August", value: "Aug" },
        { text: "September", value: "Sep" },
        { text: "October", value: "Oct" },
        { text: "November", value: "Nov" },
        { text: "December", value: "Dec" },
      ],
      yearOptions: [],
      statusOptions: [
        { text: "All Status", value: "" },
        { text: "Paid", value: "Succeeded" },
        { text: "Hold", value: "Hold" },
        { text: "Failed", value: "Failed" },
        { text: "Processing", value: "Processing" },
        { text: "Upcoming", value: "Upcoming" },
      ],
      awaitingSearch: null,
      popUpPay: false,
      popUpError: false,
      popUpErrorMessage: "",
      invoiceDataSelectedToPay: {
        invoiceNumber: "",
        invoiceId: "",
        invoiceAmount: "",
        invoiceYear: "",
        invoiceMonth: "",
        clinicId: "",
      },
      hasCreditCards: false,
      paymentType: null,
      financialDetails: null,
      defaultCard: null,
      showAddPayment: false,
      showFlag: true,
      currencyType: process.env.VUE_APP_CURRENCY,
      paymentDetails: null,
      selectedPayment: null,
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminClinicInvoice",
      "payAdminClinicInvoice",
      "deleteInvoiceDataFromDb",
      "fetchUserDetails",
      "fetchCardDetails",
      "fetchCardDetailsList",
      "fetchClinicPaymentDetails",
    ]),
    ...mapActions("organization", [
      "fetchOrganizationInvoices",
      "fetchOrganizationClinics",
    ]),
    getPdfName(row) {
      const companyName = row.clinicName ? row.clinicName : "NA";
      return `${row.invoiceNumber} ${companyName}`;
    },
    getPaymentType() {
      // if (
      //   this.paymentType == "CreditCard" ||
      //   this.paymentType == "SavedCard" ||
      //   this.paymentType == "ClinicBankAccount" ||
      //   this.paymentType == "SavedClinicBankAccount" ||
      //   this.paymentType == "PaymentTermsInvoice"
      // ) {
      //   return this.paymentType;
      // } else if (this.paymentType) {
      //   return "StoredCard";
      // }
    },
    search(searching) {
      this.searchInputString = searching;
    },
    openPopup() {
      this.popUpPay = false;
      this.showAddPayment = true;
      this.paymentType = "CreditCard";
    },
    clinicBankPaymentSuccess(val) {
      console.log(val);
      this.paymentType = "ClinicBankAccount";
      setTimeout(() => {
        this.payInvoice(val);
      }, 500);
    },
    submit() {
      this.$refs.stripeElement.submit();
    },
    tokenCreated(token) {
      this.payInvoice({
        source: token.id, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      });
    },
    async getInvoiceList() {
      this.$vs.loading();
      if (this.dataTableParams.status == "all")
        this.dataTableParams.status = "";

      await this.fetchOrganizationInvoices(this.dataTableParams)
        .then((res) => {
          this.$vs.loading.close();
          this.invoiceData = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
          this.noDataText = "No invoice available";
        })
        .catch((err) => {
          console.log("This is the error ::", err);
        });
    },
    async getUserDetail() {
      // const cardDetailsList = await this.fetchClinicPaymentDetails();
      // this.cardDetails = cardDetailsList.data.data;
    },
    formatUserDetailResponse(userDetail) {
      return {
        clinicName: userDetail.clinicName,
        address: userDetail.address,
        email: userDetail.email,
      };
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getInvoiceList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getInvoiceList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getInvoiceList();
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
    invoiceSelectedToPay(invoice) {
      this.fetchClinicPaymentDetails(invoice.clinicId)
        .then((res) => {
          this.cardDetails = res.data.data;
          const defaultValue = res.data.data.filter(
            ({ isDefault }) => isDefault
          );
          if (defaultValue.length) {
            this.selectedPayment = defaultValue[0].cardId
              ? defaultValue[0].cardId
              : defaultValue[0].paymentMethodId;
          }

          this.invoiceDataSelectedToPay = {
            invoiceNumber: invoice.invoiceNumber,
            invoiceId: invoice._id,
            invoiceAmount: parseFloat(invoice.amount) / 100,
            invoiceYear: invoice.year,
            invoiceMonth: invoice.month,
            clinicId: invoice.clinicId,
          };
          this.popUpPay = true;
          this.showFlag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelInvoiceSelectedToPay() {
      this.invoiceDataSelectedToPay = {
        invoiceNumber: "",
        invoiceId: "",
        invoiceAmount: "",
        invoiceYear: "",
        invoiceMonth: "",
      };
      this.popUpPay = false;
    },
    payInvoice(data = {}) {
      this.$vs.loading();
      this.payAdminClinicInvoice({
        invoiceId: this.invoiceDataSelectedToPay.invoiceId,
        paymentType: this.paymentType,
        paymentDetails: this.paymentDetails,
        source: null,
        ...data,
      })
        .then((result) => {
          this.$vs.notify({
            title: "Success",
            text: "Invoice paid successfully",
            color: "success",
          });

          this.cancelInvoiceSelectedToPay();
          this.$vs.loading.close();
          this.showFlag = false;
          this.showAddPayment = false;
        })
        .catch((err) => {
          this.cancelInvoiceSelectedToPay();
          if (err.status === 420) {
            this.popUpError = true;
            this.popUpErrorMessage = err.data.message;
          } else {
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger",
            });
          }
          this.$vs.loading.close();
        })
        .finally(() => {
          this.forceRerender();
        });
    },
    getName(data) {
      const name = data.clinicName ? data.clinicName : "";
      const invoiceNumber = data.invoiceNumber ? data.invoiceNumber : "";
      if (!data.invoiceNumber || data.invoiceNumber === "") {
        return "NotYetInvoiced";
      }
      return `${invoiceNumber} ${name}`;
    },
    deleteInvoiceData() {
      this.$vs.loading();
      this.deleteInvoiceDataFromDb()
        .then((result) => {
          this.$vs.notify({
            title: "Success",
            text: "Successfully flushed clinic invoice table",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        })
        .finally(() => {
          this.forceRerender();
        });
    },
    populatePaymentDetails(val) {
      let searchResult = this.cardDetails.filter(
        ({ paymentMethodId, type }) =>
          paymentMethodId === val && type === "CreditCard"
      );

      if (searchResult.length <= 0) {
        searchResult = this.cardDetails.filter(
          ({ cardId, type }) => cardId === val && type === "CreditCard"
        );
      }

      if (searchResult.length <= 0) {
        searchResult = this.cardDetails.filter(
          ({ paymentMethodId, type }) =>
            paymentMethodId === val && type === "BankAccount"
        );
      }

      if (searchResult.length) {
        this.paymentDetails = { ...searchResult[0] };
      }

      this.paymentType = null;
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    selectedPayment: function (val) {
      this.populatePaymentDetails(val);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getInvoiceList();
      }
    },
    "dataTableParams.year": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.year = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.month": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.month = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.status": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.status = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.clinic": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newVal;
        this.getInvoiceList();
      }
    },
    rerenderKey: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getInvoiceList();
      }
    },
    showAddPayment: function (current, prev) {
      if (!current && this.showFlag) {
        this.popUpPay = true;
      }
      this.paymentDetails = null;
      if (!current) {
        this.populatePaymentDetails(this.selectedPayment);
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  async created() {
    const orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    const role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    this.role = role;
    console.log(this.role);
    this.dataTableParams.organizationId = orgId
      ? orgId
      : this.$route.params.organizationId;
    const res = await this.fetchOrganizationClinics({
      id: this.dataTableParams.organizationId,
    });
    res.data.data.docs.map((clinic) => {
      this.clinics.push({
        clinicId: clinic._id,
        clinicName: clinic.clinicName,
      });
    });

    const maxYear = moment(new Date()).format("YYYY");
    const limitYear = maxYear - 30;
    for (let i = maxYear; i >= limitYear; i--) {
      const year = i.toString();
      const obj = {
        text: year,
        value: year,
      };
      this.yearOptions.push(obj);
    }
    // this.dataTableParams.year = this.yearOptions[0].value;
    if (this.clinicInvoices) {
      this.dataTableParams.clinic = this.$route.params.clinicId;
    } else {
      this.dataTableParams.clinic = null;
    }

    this.getInvoiceList();
    this.getUserDetail();
  },
};
</script>

<style scoped>
.mobile-text {
  color: #164d3d;
}
.invoice-date,
.invoice-state,
.invoice-number {
  font-size: 12px;
}

.invoice-state {
  padding: 3px 10px;
  border-radius: 100px;
}
.invoice-state-success {
  background-color: rgba(132, 205, 184, 0.12);
  color: #28c76f;
}
.invoice-state-hold,
.invoice-state-failed {
  background-color: rgba(234, 84, 85, 0.12);
  color: #ed8d8e;
}
.invoice-state-processing {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}
</style>
